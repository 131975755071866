<template>
  <div class="mt-2">
    <!-- <div :style="[!noInnerWidth ? { width: width } : {}]"> -->
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="fields"
          per-page="0"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(image)="row">
            <b-img
              class="image"
              :id="`image_${row.item.id}`"
              style="object-fit: contain"
              center
              thumbnail
              :src="getImage(row.item.id)"
              :alt="row.item.title"
            ></b-img>
          </template>

          <template #cell(actions)="row">
            <div class="action-buttons">
              <!-- <b-button
                  size="sm"
                  variant="lisaweb"
                  @click="onEdit(row.item.id)"
                  class="mt-1 mr-1"
                  title="Modifica"
                >
                  <b-icon icon="pencil-square"></b-icon>
                </b-button> -->
              <b-button
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import template_lisaweb from "./template_lisaweb";
import LisaComunicaMixin from "@/mixins/LisaComunicaMixin";
import BaseIcon from "@/components/BaseIcon";

export default {
  name: "ImagesTable",
  extends: template_lisaweb,
  mixins: [LisaComunicaMixin],
  components: {
    BaseIcon,
  },
  methods: {
    data() {
      return {
        isLoadedImage: false,
      };
    },
    getImage(id) {
      this.stream(id)
        .then((response) => {
          const blob = new Blob([response.data], { type: response.data.type });
          const blobUrl = URL.createObjectURL(blob);
          document.querySelector(`#image_${id}`).src = blobUrl;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
  },
};
</script>

<style scoped>
.image {
  display: block;
  max-width: 350px;
  max-height: 350px;
  width: auto;
  height: auto;
}
</style>
