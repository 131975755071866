<template>
  <div class="mt-1 mx-3">
    <div>
      <b-tabs content-class="pt-1" lazy v-model="tabIndex">
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="onTabClick(tab, index)"
          :title="tab.text"
          :active="active(index)"
          title-item-class="lisaweb"
        >
          <keep-alive>
            <component :is="selected"></component>
          </keep-alive>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Emails from "@/components/profile/lisacomunica/emails/index.vue";
import Sms from "@/components/profile/lisacomunica/sms/index.vue";
import Pdf from "@/components/profile/lisacomunica/pdf/index.vue";
import Images from "@/components/profile/lisacomunica/images/index.vue";
import ShortcutsAndBreadcrumbsMixin from "@/mixins/ShortcutsAndBreadcrumbsMixin";

export default {
  mixins: [ShortcutsAndBreadcrumbsMixin],
  data() {
    return {
      path: "lisacomunica_template",
      tabs: [
        { name: "Emails", text: "Email" },
        { name: "Sms", text: "Sms" },
        { name: "Pdf", text: "Pdf" },
        { name: "Images", text: "Immagini" },
      ],
      tabsGroup: "Management",
      // breadcrumbs settings: bcs & maxLevel
      bcs: [
        {
          text: "LisaComunica",
          path: "",
          level: 0,
          disabled: true,
        },
        {
          text: "LisaComunica",
          path: "",
          level: 1,
        },
        {
          text: "Modelli",
          path: "",
          level: 2,
        },
        {
          text: "Emails",
          path: "lisacomunica_template",
          tab: `#${this.selected}`,
          level: 3,
        },
      ],
      maxLevel: 3, // use to identify the tabs level
      selected: "Emails",
      tabIndex: 0,
      // isLoading: false,
    };
  },
  components: {
    Emails,
    Sms,
    Pdf,
    Images,
  },
};
</script>
