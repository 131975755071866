<template>
  <div class="mx-1">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-input
                name="type"
                label="Titolo"
                v-model="filter.title"
                placeholder="Inserisci un titolo"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    <images
      @edit="onEdit"
      :fields="fields"
      :repository="repository"
      :filterName="filterName"
      :resource="resource"
      @destroy="onDestroy"
      :ref="tableRef"
    ></images>
  </div>
</template>

<script>
import LisaComunicaMixin from "@/mixins/LisaComunicaMixin";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import Images from "@/components/tables/Images.vue";
import BaseInput from "@/components/form/BaseInput";
import { mapActions } from "vuex";

export default {
  mixins: [CustomFiltersMixin, LisaComunicaMixin],
  data() {
    return {
      repository: "image",
      resource: "image",
      tableRef: "imageTableRef",
      filterName: "filterImage",
      filter: this.initFilter(),
      fields: [
        {
          key: "actions",
          label: "Azioni",
        },
        {
          key: "title",
          label: "Titolo",
        },
        {
          key: "image",
          label: "Anteprima",
          thStyle: { width: "500px" },
        },
      ],
    };
  },
  components: {
    Images,
    BaseInput,
  },
  methods: {
    initFilter() {
      let init = {
        title: null,
      };
      return init;
    },
    onAdd() {
      this.$router.push({ name: `images.create` });
    },
    onEdit(id) {
      this.$router.push({
        name: `images.edit`,
        params: { id: `${id}` },
      });
    },
    onDestroy(id) {
      this.delete(id)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Immagine eliminata con successo`,
          });
          this.$refs[this.tableRef].fetch();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
  },
};
</script>
